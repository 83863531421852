import React from "react";
import { useEffect } from "react";
const Impressum = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="min-h-[calc(100vh-68px)] h-auto bg-[#000000]">
      <h1 className="flex justify-center items-center text-4xl font-extrabold underline pb-4"></h1>
      <div className="flex justify-center items-center  sm:flex-col lg:flex-row sm:flex-wrap pt-10"></div>
      <div className="md:pt-20 sm:pt-10 sm:mx-10 md:mx-0">
        <h1 className="text-2xl flex justify-center items-center flex-col text-white">
          <p className="font-bold text-white mb-2">BBF BIKE GmbH</p>
          <p>Carenaallee 8</p>
          <p>15366 Hoppegarten</p>
          <p>Tel. +49 3342.354 325</p>
          <p>Fax +49 3342.354 332</p>
          <p>www.bbf-bike.de</p>
          <p>info@bbf-bike.de</p>
        </h1>
        <div className="flex sm:flex-col lg:flex-row justify-center items-center my-8 gap-3 text-xl text-white">
          <div>Geschäftsführender Gesellschafter:</div>
          <h1>Hagen Stamm</h1>
        </div>

        <h1 className="text-xl flex justify-center items-center flex-col text-white sm:mx-1 md:mx-0">
          <p className="font-bold text-1xl text-white mb-2">REGISTERGERICHT</p>
          <p>Ust-ID Nr.: DE 811309641</p>
          <p>Registergericht: Landesgericht Frankfurt/Oder</p>
          <p>Registernummer: HRB 2082 FF</p>

          <p>KONZEPT & LAYOUT</p>
          <p>BBF BIKE GmbH</p>
        </h1>

        <h1 className=" flex justify-center items-center flex-col text-white mt-10">
          <p className="font-bold text-1xl text-white mb-2">
            {" "}
            AKTUALISIERUNG/HAFTUNG
          </p>
          <p className="md:w-[1200px] sm:w-[350px]">
            Die BBF BIKE GmbH ist bemüht, für die Richtigkeit und Aktualität
            aller auf ihrer Domain enthaltenen Informationen und Daten zu
            sorgen. Eine Haftung oder Garantie für die Aktualität, Richtigkeit
            und Vollständigkeit der zur Verfügung gestellten Informationen und
            Daten ist jedoch ausgeschlossen. In keinem Fall wird für Schäden,
            die sich aus der Verwendung der abgerufenen Informationen ergeben,
            eine Haftung übernommen. BBF BIKE GmbH behält sich vor, ohne
            Ankündigung, Änderungen oder Ergänzungen der bereitgestellten
            Informationen oder Daten vorzunehmen.
          </p>
        </h1>

        <h1 className=" flex justify-center items-center flex-col text-white mt-10">
          <p className="font-bold text-1xl text-white mb-2"> LINKSETZUNG</p>
          <p className="md:w-[1200px] sm:w-[350px]">
            Die BBF BIKE GmbH hat auf ihrer Domain Links zu anderen Seiten im
            Internet gesetzt. Für alle Links gilt: Die BBF BIKE GmbH betont
            ausdrücklich, dass sie keinerlei Einfluss auf die Gestaltung und die
            Inhalte der gelinkten Seiten hat. Die BBF BIKE GmbH ist für den
            Inhalt der Homepages, die mittels eines elektronischen Links
            erreicht werden, nicht verantwortlich. Sollten durch diese Inhalte
            Rechte Dritter verletzt sein, so distanziert sich die BBF BIKE GmbH
            ausdrücklich von den Inhalten dieser Seiten. Diese Erklärung gilt
            für alle auf www.bbf-bike.de ausgebrachten Links und für alle
            Inhalte der Seiten, zu denen Links führen.
          </p>
        </h1>

        <h1 className=" flex justify-center items-center flex-col text-white mt-10">
          <p className="font-bold text-1xl text-white">
            {" "}
            AKTUALISIERUNG/HAFTUNG
          </p>
          <p className="md:w-[1200px] sm:w-[350px]">
            Die BBF BIKE GmbH ist bemüht, für die Richtigkeit und Aktualität
            aller auf ihrer Domain enthaltenen Informationen und Daten zu
            sorgen. Eine Haftung oder Garantie für die Aktualität, Richtigkeit
            und Vollständigkeit der zur Verfügung gestellten Informationen und
            Daten ist jedoch ausgeschlossen. In keinem Fall wird für Schäden,
            die sich aus der Verwendung der abgerufenen Informationen ergeben,
            eine Haftung übernommen. BBF BIKE GmbH behält sich vor, ohne
            Ankündigung, Änderungen oder Ergänzungen der bereitgestellten
            Informationen oder Daten vorzunehmen.
          </p>
        </h1>

        <h1 className=" flex justify-center items-center flex-col text-white py-10">
          <p className="font-bold text-1xl text-white mb-2"> URHEBERRECHT</p>
          <p className="md:w-[1200px] sm:w-[350px]">
            Das Layout der Homepage, die verwendeten Grafiken sowie die Sammlung
            der Beiträge sind urheberrechtlich geschützt. Die Seiten dürfen nur
            zum privaten Gebrauch vervielfältigt, vorgenommen und
            Vervielfältigungsstücke weder verbreitet noch zu öffentlichen
            Wiedergaben benutzt werden. Die Vervielfältigung von Informationen
            oder Daten, insbesondere die Verwendung von Texten, Textzeilen oder
            Bildmaterial bedarf der vorherigen Zustimmung der BBF BIKE GmbH.
          </p>
        </h1>
      </div>
    </div>
  );
};

export default Impressum;
