import React from "react";
import { useEffect, useState } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
function AboutUs() {
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const listOfBikes = async () => {
    try {
      const response = await axios.get(
        "https://data.bbf-bike.de/catalog/list/categories/25"
      );

      setData(response.data);
    } catch (error) {
      console.log("An error generated", error);
    }
  };
  useEffect(() => {
    listOfBikes();
  }, []);

  const { i18n } = useTranslation();

  const currentLanguage = i18n.language;

  const german = data[9]?.translations.german.DESCRIPTION;
  const english = data[9]?.translations.english.DESCRIPTION;
  return (
    <div className="min-h-[calc(100vh-68px)] h-auto bg-[#000000] flex justify-center items-center">
      <div className="container sm:my-20 lg:my-0 sm:mx-5 md:flex sm:flex sm:flex-col lg:flex-row md:items-center sm:items-center sm:justify-center">
        <div className="lg:w-1/2 mb-4 lg:mb-0 pr-4 ml-10 ">
          <h1 className="text-2xl font-semibold mb-4 text-white ">
            {" "}
            {t("About Us")}
          </h1>
          <p className="text-white">
            {currentLanguage === "de" ? german : english}
          </p>
        </div>
        <div className="lg:w-1/2 sm:mt-10 lg:mt-40 lg:mb-10">
          <img
            src={`https://media.bbf-bike.de/shop/images/${data[9]?.bbf_cat_symbol}`}
            alt="About Us"
            className="rounded-lg object-contain"
          />
        </div>
      </div>
    </div>
  );
}

export default AboutUs;
