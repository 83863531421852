import React, { useEffect } from "react";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import logo from "../Images/VIVA-logo.png";
import { faFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons";
import { Link } from "react-router-dom";
import {useTranslation} from "react-i18next"

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  
  const {t}= useTranslation();

  const { i18n } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };
  const handleEnglish=(language)=>{
  i18n.changeLanguage(language)
  localStorage.setItem('language', language)
  }
  const savedLanguage = localStorage.getItem('language')

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div>
      <nav className={`${isScrolled? "bg-white animate-in fade-in":"bg-[#000000]"} transition-all delay-50 duration-500 ease-in-out md:fixed  z-50 w-screen md:h-[90px] sm:pb-3 md:pb-0 top-0 left-0`} >
        <div className="mx-auto mx-4 md:px-6 lg:px-8">
          <div className="flex items-center justify-between h-16">
            <div className="flex items-center">
              <Link to="/">
                {" "}
                <img
                  src={logo}
                  className="lg:h-24 lg:w-48 sm:h-12 md:h-20  sm:pl-3 cursor-pointer object-contain sm:pt-1 md:pt-[32px]"
                  onClick={()=>setMenuOpen(false)}
                />
              </Link>
            </div>
            <div className="hidden md:flex space-x-5 pt-8">
              <div className="relative group">
               <Link to="/allbikes">   
              <h1
              
                className={`${isScrolled?"text-[#000000]":"text-white"} hover:text-[#A07A10] relative group`}
              >
         
              {t("Bikes")}
              </h1>

              </Link>
              <div className="hidden absolute left-0 py-3 bg-gray-800 text-white group-hover:block bg-opacity-90">
              <ul>
                <li>
                  <Link to="/lady">
                  <h1 className="block px-4 py-2 hover:text-[#A07A10]">{t("Buywomensbike")}</h1>
                  </Link>
                </li>
                <li>
                  <Link to="/men">
                  <h1  className="block px-4 py-2 hover:text-[#A07A10]">{t("Buymensbike")}</h1>
                  </Link>
                </li>
                <li>
                  <Link to="/cargo">
                  <h1  className="block px-4 py-2 hover:text-[#A07A10]">{t("Cargo")}</h1>
                  </Link>
                </li>
              </ul>
            </div>

              </div>
              <Link to="/aboutus">
              <h1
              
                className={`${isScrolled?"text-[#000000]":"text-white"} hover:text-[#A07A10]`}
              >
              {t("About Us")}
              </h1>
              </Link>


              <div className="relative group">
                <Link to="#">
              <h1
                
                className={`${isScrolled?"text-[#000000]":"text-white"} hover:text-[#A07A10] relative group`}
              >
         
              {t("Dealers")}
              </h1>
              </Link>
              <div className="hidden absolute left-0 py-3 bg-gray-800 text-white group-hover:block bg-opacity-90">
              <ul>
                <li>
                  <Link to="/dealers">
                  <h1  className="block px-4 py-2 hover:text-[#A07A10]">{t("Dealers search")}</h1>
                  </Link>
                </li>
                <li>
                  <Link to="/distributors">
                  <h1 className="block px-4 py-2 hover:text-[#A07A10]">{t("Distributors")}</h1>
                  </Link>
                </li>
              </ul>
            </div>

              </div>
              <Link to="/contact">
              <h1
                className={`${isScrolled?"text-[#000000]":"text-white"} hover:text-[#A07A10]`}
              >
                  {t("Contact")}
              </h1>
              </Link>
              <Link to="/downloads">
              <h1
               className={`${isScrolled?"text-[#000000]":"text-white"} hover:text-[#A07A10]`}
              >
                   {t("Downloads")}
              </h1>
              </Link>
            </div>
           	<div class="flex flex-row items-center right-1 md:pt-8">
		<button class={`p-2 flex flex-row items-center ${isScrolled?"text-[#000000] ":"text-white "} text-sm font-medium text-gray-700   focus:focus:outline-none`} style={{textDecoration: savedLanguage==="de"?"underline":"", fontWeight:savedLanguage=="de"?"700":"",textUnderlineOffset:savedLanguage==="de"?"5px":""}}
                   onClick={()=>handleEnglish("de")} >
                        <span class="text-md">DE</span>
                        <span class="ml-1"> <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAY
                        AAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAAEFklEQVR4nO2ZyWsbVxzHfyRuTj2k7Sk0PXVvkrZuvMRLLC9ZJEu
                        yLGkkWdbMaGYeKOCUJj2FQhr50uDkUru30j8gIdBSQintydCcCgUtljcocWulkSXZlhfsmiTSt8yYFCwKkqxtCv7C56aBz
                        /e9p3kz84gOcpCDlJ1gMHiIMdYiy/JVSZLuiaIYFkUxLoritiAI2zzPx30+X9jn893zer1X3G53s3oN1TuSJL2mKEpQkqQ/Z
                        FmGJEnw+/0QRVFDEATwPK/h8/kwPDwMr9erMTQ0tODxeG7Y7fbjNRf3+/1HGWNjsizvqOL7kIfH44Hb7YbL5XrCcdyEzWY7WhN
                        5RVGcjLGUoigoV969WwAcx8HpdKacTqejauLBYLBBHXXGWK7S8txuATgcDtjt9q8DgcALFZV3uVxHGGM/MMZQZXmoDA4O3q9YCZf
                        LdVhRlLs1lIfNZlP5zmAwNJRdgDH2VR3kMTAwAIvFMl6WvCzLphqsefyXvNVqVQvkLBaLdV/ygiC8oihKuo7yUDGbzSmz2fxSyQUYY7d1II/
                        +/n6YTKaxkuQDgcAxWZa3dCIPo9G4bTQai9+x06O3H2xah7Bp8WDTrOLGRr8bGyYXNkwcNowc1i86sX7Bsct5O9bODWKtT8WGtd4BZHpUrMh0W5ExWLDaZd7lbD9WO01Y6TBipd2IlbaLWDlzAcut57Hceg7LLX1IN/ch3dSL9OkepD/qRqrRgFlh5Jei5DE52ZDhLz3Tk3zqwy7EOy1PJw3BwrfVxPg3st7kUx90Ifn+WYQ+vsYXLJAavfWzHuWTpzoR40d+LFhg5er1R3qUT57swLzVt1iwwKo0sqNH+eSJdjzssv5dsECG8+f0KL90og3x5t5swQLrliFdyi+914bEqQ4UUcCjS/mld8/gr5PthQtkHEJOj/KJd1qx2NhdeAkt85d29CifeLsFv7eZCv+Jk5evxfUon3irGTNGrvBt9NFnX/ykR/nEm00IcVLhjezPm+N+Pco/fqMJv0qXh4t6mFu2+5/pTX6hsefpZLHvyPFPbzzQk/zj108jNMAX9zitzcIMHcvO0VZunvAvc3nM7oLnzOQxnUcsj6k8onlE9rCNEL1KpSQ7T7d0Ig+E6GZJ8tosxOjl7ByldCCfRJRKf6nXSsyRKTtLuTrK5xCm/X1WeZ7sHE3URT6s8WVZ8tosgA5nZ+lOzeVD9C0mqfxPi1qJGB3BDN2v4ch/j9+osl+o1ZnANI1hmnJVXfMhmqi4/J4is+TENKWqMPIphKl6Bxx7SsToRUzTKGK0UwH5J9qoh6g2R0x7ikToOGL0OaZoYR/yDxGh6yXvsFUpAjqEKDVhiq4gSncRpRCitIgobWlEaBERCiFCdxCmT7Tfgup/zHqQg9D/P/8A9+JSzY86f54AAAAASUVORK5CYII=" class="w-5 h-5" /></span>
                    </button>

		<button class={`p-2 flex flex-row items-center ${isScrolled?"text-[#000000] ":"text-white "}
    text-sm font-medium text-gray-700  focus: focus:outline-none`} style={{textDecoration: savedLanguage==="en"?"underline":"", fontWeight:savedLanguage==="en"?"700":"",textUnderlineOffset:savedLanguage==="en"?"5px":""}}
                    onClick={()=>handleEnglish("en")}>
                        <span class="text-md">EN</span>
                         <span class="ml-1"> <img src="https://img.icons8.com/?size=512&id=t3NE3BsOAQwq&format=png" class="w-5 h-5" /></span>
                    </button>
	</div>
                
            <div className="items-center space-x-4 ml-32 hidden lg:block pt-8 mr-5">
              <a
                href="https://www.facebook.com/vivabikedesign"
                target="blank"
                className={`${isScrolled?"text-[#000000]":"text-white"} hover:text-[#A07A10]`}
              >
                <FontAwesomeIcon icon={faFacebook} />
              </a>
              <a
                href="https://www.instagram.com/vivabikes/"
                target="blank"
                className={`${isScrolled?"text-[#000000]":"text-white"} hover:text-[#A07A10]`}
              >
                <FontAwesomeIcon icon={faInstagram} />
              </a>
            </div>
            <div className="md:hidden flex items-center pr-5">
              <button
                onClick={toggleMenu}
                className="text-white hover:text-gray-300 focus:outline-none"
              >
                <svg
                  className="h-6 w-6 fill-current"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M3 18a1 1 0 010-2h18a1 1 0 010 2H3zM3 12a1 1 0 010-2h18a1 1 0 010 2H3zM3 6a1 1 0 110-2h18a1 1 0 110 2H3z"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
        {menuOpen && (
          <div className="md:hidden ">
            <Link to="/allbikes">
            <h1
             
              className={`block py-2 px-4 ${isScrolled?"text-[#000000]":"text-white"} hover:text-[#A07A10]`}
              onClick={()=>setMenuOpen(!menuOpen)}
            >
               {t("Bikes")}
            </h1>
            </Link>
            <Link to="/aboutus">
            <h1
              
              className="block py-2 px-4 text-white hover:text-[#A07A10] "
              onClick={()=>setMenuOpen(!menuOpen)}
            >
             {t("About Us")}
            </h1>
            </Link>
            <Link to="/dealers">
            <h1
              
              className="block py-2 px-4 text-white hover:text-[#A07A10] "
              onClick={()=>setMenuOpen(!menuOpen)}
            >
               {t("Dealers search")}
            </h1>
            </Link>
            <Link to="/distributors">
            <h1
              className="block py-2 px-4 text-white hover:text-[#A07A10] "
              onClick={()=>setMenuOpen(!menuOpen)}
            >
            {t("Distributors")}
            </h1>
            </Link>
           <Link to="/contact">
            <h1
              className="block py-2 px-4 text-white hover:text-[#A07A10] "
              onClick={()=>setMenuOpen(!menuOpen)}
            >
              {t("Contact")}
            </h1>
            </Link>
            <Link to="/downloads">
            <h1
             
              className="block py-2 px-4 text-white hover:text-[#A07A10] "
              onClick={()=>setMenuOpen(!menuOpen)}
            >
              
          {t("Downloads")}
            </h1>
            </Link>
            <a
                href="https://www.facebook.com/vivabikedesign"
                target="blank"
                className={`${isScrolled?"text-[#000000]":"text-white"} hover:text-[#A07A10] mx-5`}
              >
                <FontAwesomeIcon icon={faFacebook} />
              </a>
              <a
                href="https://www.instagram.com/vivabikes/"
                target="blank"
                className={`${isScrolled?"text-[#000000]":"text-white"} hover:text-[#A07A10]`}
              >
                <FontAwesomeIcon icon={faInstagram} />
              </a>
          </div>
        )}
      </nav>
    </div>
  );
};

export default Header;
