import React from "react";
import { useEffect } from "react";

const Dataprotection = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="min-h-[calc(100vh-68px)] h-auto bg-[#000000]">
      <h1 className="md:flex md:justify-center md:items-center sm:flex-none text-4xl font-extrabold underline pb-4"></h1>
      <div className="md:flex md:justify-center md:items-center sm:flex-none  pt-10"></div>
      <div className="md:py-20 sm:py-10  sm:mx-5 md:mx-56  text-white">
        Nachfolgend erläutern wir, welche Daten wir von Ihnen zu welchen Zwecken
        verarbeiten und welche Rechte Sie diesbezüglich haben.
        <h1 className="text-xl font-bold my-3 text-white">Datenerhebung</h1>
        Wir erheben Daten beim Anbahnen, beim Abschluss, beim Abwickeln und beim
        Rückabwickeln eines Kaufvertrages. Diese Daten werden von uns erhoben,
        gespeichert und verarbeitet. Ihr Besuch auf unseren Webseiten wird
        protokolliert. Erfasst werden im Wesentlichen die aktuell von Ihrem PC
        verwendete IP-Adresse, Datum und Uhrzeit, der Browsertyp und das
        Betriebssystem Ihres PC sowie die von Ihnen betrachteten Seiten. Ein
        Personenbezug ist uns im Regelfall nicht möglich und auch nicht
        beabsichtigt.
        <h1 className="text-xl font-bold my-3 text-white">
          Datenverwendung und -weitergabe
        </h1>
        Die personenbezogenen Daten, die Sie uns z.B. durch ein Kontaktformular,
        bei einer Bestellung oder per E-Mail mitteilen (z.B. Ihr Name und Ihre
        Adresse oder Ihre E-Mail-Adresse), werden nur zur Korrespondenz mit
        Ihnen und nur für den Zweck verarbeitet, zu dem Sie uns die Daten zur
        Verfügung gestellt haben. Wir geben Ihre Daten nur an das mit der
        Lieferung beauftragte Versandunternehmen weiter, soweit dies zur
        Lieferung der Waren notwendig ist. Zur Abwicklung von Zahlungen geben
        wir Ihre Zahlungsdaten nur an das mit der Zahlung beauftragte
        Kreditinstitut weiter. Wir versichern, dass wir Ihre personenbezogenen
        Daten im übrigen nicht an Dritte weitergeben, es sei denn, dass wir dazu
        gesetzlich verpflichtet waren oder Sie uns vorher Ihre Zustimmung
        gegeben haben. Soweit wir zur Durchführung und Abwicklung von
        Verarbeitungsprozessen Dienstleister in Anspruch nehmen, werden die
        Vertragsverhältnisse nach den Bestimmungen des Bundesdatenschutzgesetzes
        geregelt.
        <h1 className="text-xl font-bold my-3 text-white">
          Einwilligung und Widerruf
        </h1>
        Wenn Sie uns personenbezogene Daten überlassen haben, können sie diese
        jederzeit wieder löschen. Daten für Abrechnungs- und buchhalterische
        Zwecke sind von einer Kündigung/einem Widerruf bzw. von einer Löschung
        nicht berührt.
        <h1 className="text-xl font-bold my-3 text-white">Speicherdauer</h1>
        Personenbezogene Daten, die uns über unsere Website mitgeteilt worden
        sind, werden nur so lange gespeichert, bis der Zweck erfüllt ist, zu dem
        sie uns anvertraut wurden. Soweit handels- und steuerrechtliche
        Aufbewahrungsfristen zu beachten sind, kann die Speicherdauer zu
        bestimmten Daten bis zu 10 Jahre betragen.
        <h1 className="text-xl font-bold text-white">Verwendung von Cookies</h1>
        Um den Besuch unserer Website attraktiv zu gestalten und die Nutzung
        bestimmter Funktionen zu ermöglichen, verwenden wir auf verschiedenen
        Seiten sogenannte Cookies. Hierbei handelt es sich um kleine
        Textdateien, die auf Ihrem Endgerät abgelegt werden. Einige der von uns
        verwendeten Cookies werden nach dem Ende der Browser-Sitzung, also nach
        Schlie§en Ihres Browsers, wieder gelöscht (sog. Sitzungs-Cookies).
        Andere Cookies verbleiben auf Ihrem Endgerät und ermöglichen uns oder
        unseren Partnerunternehmen, Ihren Browser beim nächsten Besuch wieder
        zuerkennen (persistente Cookies). Sie können Ihren Browser so
        einstellen, dass Sie über das Setzen von Cookies informiert werden und
        einzeln über deren Annahme entscheiden oder die Annahme von Cookies für
        bestimmte Fälle oder generell ausschlie§en. Bei der Nichtannahme von
        Cookies kann die Funktionalität unserer Website eingeschränkt sein.
        <h1 className="text-xl font-bold my-3 text-white">Kundenkonto</h1>
        Wenn Sie ein Benutzerkonto eröffnen, willigen Sie damit ein, dass Ihre
        Bestandsdaten wie zum Beispiel Name, Adresse und Ihre E-Mailadresse
        sowie Ihre Nutzungsdaten (Benutzername, Passwort) gespeichert werden.
        Dadurch haben Sie die Möglichkeit, mit Ihrer E-Mailadresse sowie Ihrem
        persönlichen Passwort bei uns zu bestellen.
        <h1 className="text-xl font-bold my-3 text-white">Chatbot</h1>
        Zweck der Daten Diese Liste stellt die Zwecke der Datenerhebung und
        -verarbeitung dar.
        <br />
        · Chatverlauf beibehalten
        <br />
        · Die IP-Adresse des Abrufgerätes
        <br />
        · Datum und Uhrzeit des Zugriffs
        <br />
        · Website von der aus Zugegriffen wurde
        <br />
        · Rechtliche Grundlage
        <br />
        Im Folgenden wird die erforderliche Rechtsgrundlage für die Verarbeitung
        von Daten genannt
        <br />
        Art. 6 Abs. 1 s. 1 lit. c DSGVO
        <h1 className="text-xl font-bold my-3 text-white">
          Ihre Rechte, Auskunft
        </h1>
        Sollten Sie mit der Speicherung Ihrer personenbezogenen Daten nicht mehr
        einverstanden oder diese unrichtig geworden sein, werden wir auf eine
        entsprechende Weisung hin die Löschung oder Sperrung Ihrer Daten
        veranlassen oder die notwendigen Korrekturen vornehmen (soweit dies nach
        dem geltendem Recht möglich ist). Auf Wunsch erhalten Sie unentgeltlich
        Auskunft über alle personenbezogenen Daten, die wir über Sie gespeichert
        haben. Bei Fragen zur Erhebung, Verarbeitung oder Nutzung Ihrer
        personenbezogenen Daten, bei Auskünften, Berichtigung, Sperrung oder
        Löschung von Daten wenden Sie sich bitte per E-Mail an
        datenschutz@bbf-bike.de.
      </div>
    </div>
  );
};

export default Dataprotection;
