import React from "react";
import { FaEnvelope, FaPhone } from "react-icons/fa";
import { useState, useEffect } from "react";
import axios from "axios";
import validator from "validator";
import { useTranslation } from "react-i18next";
function Contact() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState("");
  const [invalidEmailId, setInvalidEmailId] = useState("");
  const { t } = useTranslation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const handleSubmit = (e) => {
    e.preventDefault();

    if (validator.isEmail(email)) {
      axios
        .post("https://api.bbf-bike.de/api/mailer/viva", {
          name: name,
          email: email,
          subject: subject,
          nachricht: message,
        })
        .then((res) => console.log(res))
        .catch((err) => console.log(err));

      setSuccess(t("successfull"));
      setName("");
      setEmail("");
      setSubject("");
      setMessage("");
    } else {
      setInvalidEmailId(t("Invalidemail"));
      setSuccess("");
    }
  };
  const handleKeyDown = (event) => {
    if (event.key === "Enter" && success) {
      setName("");
      setEmail("");
      setSubject("");
      setMessage("");
    }
  };
  const placeHolders = {
    nameP: ` ${t("Your Name")}`,
    emailp: ` ${t("You@example")}`,
    subjectp: ` ${t("Subject")}`,
    messagep: ` ${t("Message")}`,
  };

  return (
    <div className="min-h-[calc(100vh-68px)] h-auto bg-[#000000]">
      <div className="bg-[#000000] p-8 mx-auto max-w-md text-white">
        <div>
          <h2 className="text-4xl  font-semibold mb-4 sm:mt-10 md:mt-44">
            {t("Contact")}
          </h2>
          <h2>{t("Contactdes")}</h2>
          <div className="flex sm:flex-col md:flex-row  gap-5 mt-10 mb-10 justify-between items-center">
            <div className="flex  items-center space-x-3">
              <FaEnvelope className=" text-3xl" />
              <p className="">viva@bbf-bike.de</p>
            </div>

            <div className="flex items-center space-x-2">
              <FaPhone className=" text-3xl" />
              <p className="">+49 3342 354325</p>
            </div>
          </div>

          {invalidEmailId && !success ? (
            <div
              className="flex items-center p-4 mb-4 mt-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400 dark:border-red-800"
              role="alert"
            >
              <svg
                className="flex-shrink-0 inline w-4 h-4 mr-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
              </svg>
              <span className="sr-only">Info</span>
              <div>
                <span className="font-medium">{invalidEmailId}</span>
              </div>
            </div>
          ) : (
            <div></div>
          )}
          <form className="shadow-2xl p-8 mb-20" onSubmit={handleSubmit}>
            <div className="mb-4">
              <label
                htmlFor="name"
                className="block text-white text-sm font-medium mb-2"
              >
                {t("Name")}
              </label>
              <input
                type="text"
                id="name"
                name="name"
                className="w-full p-2 border border-gray-300 text-black rounded outline-none"
                placeholder={placeHolders.nameP}
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="email"
                className="block text-white text-sm font-medium mb-2"
              >
                {t("Email")}
              </label>
              <input
                type="email"
                id="email"
                name="email"
                className="w-full p-2 border border-gray-300 text-black rounded outline-none"
                placeholder={placeHolders.emailp}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <label
                htmlFor="name"
                className="block text-white text-sm font-medium mb-2"
              >
                {t("Subject")}
              </label>
              <input
                type="text"
                id="name"
                name="name"
                className="w-full p-2 border border-gray-300 text-black rounded outline-none"
                placeholder={placeHolders.subjectp}
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="message"
                className="block text-white text-sm font-medium mb-2"
              >
                {t("Message")}
              </label>
              <textarea
                id="message"
                name="message"
                className="w-full p-2 border h-40 border-gray-300 text-black rounded outline-none"
                placeholder={placeHolders.messagep}
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              ></textarea>
            </div>
            <div className="mb-4">
              <button
                onClick={handleSubmit}
                onKeyDown={handleKeyDown}
                type="submit"
                className="bg-[#A07A10] rounded-md w-24 h-10 hover:bg-black hover:text-white font-bold "
              >
                {t("Submit")}
              </button>
              {success ? (
                <div
                  className="flex items-center p-4 my-4 text-green-800 rounded-lg bg-green-50 dark:bg-gray-800 dark:text-green-400"
                  role="alert"
                >
                  <svg
                    className="flex-shrink-0 w-4 h-4"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                  </svg>
                  <span className="sr-only">Info</span>
                  <div className="ml-3 text-sm font-medium">{success}</div>
                </div>
              ) : (
                <div></div>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Contact;
