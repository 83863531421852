import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
const CategoryList = () => {
  const [list, setList] = useState([]);
  const { categoryname} = useParams();
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);
  const listArray = async () => {
    try {
      const response = await axios.get(
        "https://data.bbf-bike.de/catalog/list/categories/25"
      );
      setList(response.data);
    } catch (error) {
      console.log("error generated", error);
    }
  };
  useEffect(() => {
    listArray();
  }, []);

  const catID =  list?.find((item) => item.bbf_cat_SHORTNAME === categoryname)?.bbf_cat_ID_PUBLICGRPORDER
  const categoryListBikes = list?.filter(
    (item) => item.bbf_cat_parent_category === catID
  ).sort((a,b )=> a.bbf_cat_position - b.bbf_cat_position);
  
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  return (
    <div className=" min-h-[calc(100vh-68px)] h-auto bg-[#000000] flex justify-center items-center">
      {categoryListBikes ? (
        <div className="flex justify-center items-center flex-wrap md:pt-40 sm:pt-10">
          {categoryListBikes?.map((item, index) => (
            <div key={index} className="p-12  relative">
              <img
                src={`https://media.bbf-bike.de/shop/images/${item?.bbf_cat_symbol}`}
                alt="bike"
                className="object-cover md:w-96 md:h-96 sm:h-60 sm:w-60  rounded-lg border-2 border-gray-300  md:p-0 shadow-md"
              />
              <Link
                to={`${item?.bbf_cat_SHORTNAME}`}
              >
                <h1 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white absolute inset-0 flex items-center justify-center transform hover:scale-105 transition-transform duration-300">
                  <p className="text-white text-xl font-bold bg-black bg-opacity-50 p-4">
                    {item?.bbf_cat_name}
                  </p>
                </h1>
              </Link>
            </div>
          ))}
        </div>
      ) : (
        <div className="flex items-center justify-center h-screen">
          <div className="flex flex-col items-center">
            <div className="w-16 h-16 border-t-4 border-blue-500 border-solid rounded-full animate-spin mb-4"></div>
            <p className="text-gray-600 text-lg">Loading...</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default CategoryList;
