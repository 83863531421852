import React from "react";
import classNames from "classnames";
import { useState, useEffect } from "react";
import logo from "../Images/VIVA-logo.png";
import sliderImage1 from "../Images/slider1.jpg";
import sliderImage2 from "../Images/slider2.jpg";
import sliderImage3 from "../Images/slider3.jpg";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styles from "../MyComponent.module.css";

const Slider = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const { t } = useTranslation();
  const prevImage = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const nextImage = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };
  useEffect(() => {
    const timer = setTimeout(() => {
      const nextIndex = (currentIndex + 1) % images.length;
      setCurrentIndex(nextIndex);
    }, 5000);
    return () => clearTimeout(timer);
  }, [currentIndex]);
  const images = [
    {
      image: sliderImage2,
      description: ` ${t("Buywomensbike")}`,
      url: "/lady",
    },
    {
      image: sliderImage1,
      description: ` ${t("Buymensbike")}`,
      url: "/men",
    },
    {
      image: sliderImage3,
      description: ` ${t("Cargo")}`,
      url: "/cargo",
    },
  ];
  return (
    <div>
      <div className="relative">
        {images.map((image, index) => (
          <div className=" flex justify-center items-center sm:bg-[#000000] md:bg-[#000000]">
            <img
              key={index}
              src={image.image}
              alt={`Slide ${index + 1}`}
              className={classNames("md:object-cover md:h-screen sm:w-full", {
                hidden: index !== currentIndex,
                block: index === currentIndex,
              })}
            />
          </div>
        ))}
        <button
          type="button"
          className="absolute top-0 left-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none"
          data-carousel-prev
          onClick={prevImage}
        >
          <span className="inline-flex items-center justify-center sm:w-6 sm:h-6  lg:w-10 lg:h-10 rounded-full  md:bg-white/30 dark:bg-gray-800/30 group-hover:bg-white/50 dark:group-hover:bg-gray-800/60 group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none">
            <svg
              className="w-4 h-4 text-white dark:text-gray-800"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 6 10"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M5 1 1 5l4 4"
              />
            </svg>
            <span className="sr-only">Previous</span>
          </span>
        </button>
        <button
          type="button"
          className="absolute top-0 right-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none"
          onClick={nextImage}
          data-carousel-next
        >
          <span className="inline-flex items-center justify-center sm:w-6 sm:h-6  lg:w-10 lg:h-10 rounded-full md:bg-white/30 dark:bg-gray-800/30 group-hover:bg-white/50 dark:group-hover:bg-gray-800/60 group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none">
            <svg
              className="w-4 h-4 text-white dark:text-gray-800"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 6 10"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="m1 9 4-4-4-4"
              />
            </svg>
            <span className="sr-only">Next</span>
          </span>
        </button>
      </div>
    </div>
  );
};

export default Slider;
