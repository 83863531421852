import { Link } from "react-router-dom";
import section1 from "../Images/section1.jpg";
import section2 from "../Images/section2.jpg";
import section3 from "../Images/section3.jpg";
import section4 from "../Images/section4.jpg";
import {useTranslation} from "react-i18next"
function Section() {
  const {t}= useTranslation();
  return (
    <div>
      <div className="grid md:grid-cols-2 sm:grid-cols-1 lg:gap-2 sm:gap-3 md:gap-6 md:mt-40 lg:mt-0 pb-10 sm:mx-3 lg:mx-2">
        <div className="relative group">
          <img
            className="h-auto max-w-full rounded-lg group-hover:opacity-50 "
            src={section2}
            alt=""
          />
           <div className="absolute inset-0 flex justify-center items-center group-hover:opacity-100 z-30 "> <Link to="/lady"><button className="bg-[#A07A10] text-white p-3 rounded-md hover:bg-[#F5F5F5] hover:text-[#A07A10]">{t("womenbikes")}</button></Link></div>
          <div className="absolute inset-0 bg-black opacity-0 group-hover:opacity-50 transition-opacity duration-300">
            {" "}
            {/* <div className="flex flex items-center justify-center">
              <Link to="/lady">
                <button
                  type="button"
                  className="lg:mt-80 sm:mt-10 bg-[#A07A10] text-white font-bold opacity-100 rounded-md w-40 h-10 hover:bg-[#000000] hover:text-white sm:text-xs md:text-lg"
                >
                {t("womenbikes")}
                </button>
              </Link>
            </div> */}
           
          </div>
        </div>

        <div className="relative group">
          <img
            className="h-auto max-w-full rounded-lg group-hover:opacity-50 "
            src={section1}
            alt=""
          />
           <div className="absolute inset-0 flex justify-center items-center group-hover:opacity-100 z-30 "> <Link to="/men"><button className="bg-[#A07A10] text-white p-3 rounded-md hover:bg-[#F5F5F5] hover:text-[#A07A10]">{t("Menbikes")}</button></Link></div>
          <div className="absolute inset-0 bg-black opacity-0 group-hover:opacity-50 transition-opacity duration-300">
            {" "}
            {/* <div className="flex flex items-center justify-center">
              <Link to="/men">
                {" "}
                <button
                  type="button"
                  className="lg:mt-80 sm:mt-10 bg-[#A07A10] text-white font-bold rounded-md w-40 h-10 hover:bg-[#000000] hover:text-white sm:text-xs md:text-lg"
                >
               {t("Menbikes")}
                </button>
              </Link>
            </div> */}
          </div>
        </div>
        <div className="relative group">
          <img
            className="h-auto max-w-full rounded-lg group-hover:opacity-50 "
            src={section4}
            alt=""
          />
          <div className="absolute inset-0 flex justify-center items-center group-hover:opacity-100 z-30 "> <Link to="/cargo"><button className="bg-[#A07A10] text-white p-3 rounded-md hover:bg-[#F5F5F5] hover:text-[#A07A10]">{t("Cargobikes")}</button></Link></div>
          <div className="absolute inset-0 bg-black opacity-0 group-hover:opacity-50 transition-opacity duration-300">
            {" "}
            {/* <div className="flex flex items-center justify-center">
              <Link to="/cargo">
                {" "}
                <button
                  type="button"
                  className="lg:mt-80 sm:mt-10 bg-[#A07A10] text-white font-bold   rounded-md w-40 h-10 hover:bg-[#000000] hover:text-white sm:text-xs md:text-lg"
                >
                 {t("Cargobikes")}
                </button>
              </Link>
            </div> */}
          </div>
        </div>
        <div className="relative group">
          <img
            className="h-auto max-w-full rounded-lg group-hover:opacity-50 "
            src={section3}
            alt=""
          />
            <div className="absolute inset-0 flex justify-center items-center group-hover:opacity-100 z-30 "> <Link to="/allbikes"><button className="bg-[#A07A10] text-white p-3 rounded-md hover:bg-[#F5F5F5] hover:text-[#A07A10]">{t("Allbikes")}</button></Link></div>
          <div className="absolute inset-0 bg-black opacity-0 group-hover:opacity-50 transition-opacity duration-300">
            {" "}
            {/* <div className="flex flex items-center justify-center">
              <Link to="/allbikes">
                {" "}
                <button
                  type="button"
                  className="lg:mt-80 sm:mt-10 bg-[#A07A10] text-white font-bold  rounded-md w-40 h-10 hover:bg-[#000000] hover:text-white sm:text-xs md:text-lg"
                >
                  {t("Allbikes")}
                </button>
              </Link>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Section;
