import React from 'react'
import { useEffect } from 'react';
const Distributors = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, []);
  return (
    <div className='min-h-[calc(100vh-68px)] h-auto bg-[#000000]'>
        <div className='flex justify-center items-center'>
        <div className='grid md:grid-cols-3 sm:grid-cols-1 sm::gap-2 md:gap-36   text-white bg-[#000000]  py-10'>

             <div className='md:pt-40 sm:pt-0'>
            <div className='hover:bg-gray-700 hover:bg-opacity-50 text-white p-10 w-60 cursor-pointer rounded-lg'>
            <div className='flex pb-5'>
            <img  src='https://img.icons8.com/color/96/germany.png' className='w-8 h-8' alt='Germany'/>
             <h2 className='pl-4 font-bold'>Germany</h2>
            </div>
            <div>
            +49 3342 354321<br/>
               viva@bbf-bikes.de
            </div>
            </div>
          
            </div>
            <div className='md:pt-40 sm:pt-0'>
            <div className='hover:bg-gray-700 hover:bg-opacity-50 text-white p-10 w-60 cursor-pointer rounded-lg'>
            <div className='flex pb-5'>
             <img src='https://img.icons8.com/color/96/austria-circular.png'  className='w-8 h-8' alt='austria'/>
             <h2 className='pl-4 font-bold'>Austria</h2>
            </div>
            <div>
            +49 3342 354321<br/>
                viva@bbf-bikes.de
            </div>
            </div>
          
            </div>
            <div className='md:pt-40 sm:pt-0'>
            <div className='hover:bg-gray-700 hover:bg-opacity-50 text-white p-10 w-60 cursor-pointer rounded-lg'>
            <div className='flex pb-5'>
            <img  src='https://img.icons8.com/color/96/czech-republic.png' className='w-8 h-8' alt='Czech Republic'/>
             <h2 className='pl-4 font-bold'>Czech Republic</h2>
            </div>
            <div>
            +49 3342 354321<br/>
                viva@bbf-bikes.de
            </div>
            </div>
          
            </div>
            <div>
            <div className='hover:bg-gray-700 hover:bg-opacity-50 text-white p-10 w-60 cursor-pointer rounded-lg'>
            <div className='flex pb-5'>
            <img  src='https://img.icons8.com/color/96/denmark-circular.png' className='w-8 h-8' alt='Denmark'/>
             <h2 className='pl-4 font-bold'>Denmark</h2>
            </div>
            <div>
            +49 3342 354321<br/>
                viva@bbf-bikes.de
            </div>
            </div>
          
            </div>
            <div>
            <div className='hover:bg-gray-700 hover:bg-opacity-50 text-white p-10 w-60 cursor-pointer rounded-lg'>
            <div className='flex pb-5'>
            <img  src='https://img.icons8.com/color/96/poland-circular.png' className='w-8 h-8' alt='Poland'/>
             <h2 className='pl-4 font-bold'>Poland</h2>
            </div>
            <div>
            +49 3342 354321<br/>
                viva@bbf-bikes.de
            </div>
            </div>
          
            </div>
          
            <div>
            <div className='hover:bg-gray-700 hover:bg-opacity-50 text-white p-10 w-60 cursor-pointer rounded-lg'>
            <div className='flex pb-5'>
             <img src='https://img.icons8.com/emoji/96/switzerland-emoji.png'  className='w-8 h-8' alt='swiss'/>
             <h2 className='pl-4 font-bold'>Switzerland</h2>
            </div>
            <div>
            +49 3342 354321<br/>
                viva@bbf-bikes.de
            </div>
            </div>
          
            </div>
            <div>
            <div className='hover:bg-gray-700 hover:bg-opacity-50 text-white p-10 w-60 cursor-pointer rounded-lg'>
            <div className='flex pb-5'>
             <img src='https://img.icons8.com/color/48/tunisia.png'  className='w-8 h-8' alt='tunasia'/>
             <h2 className='pl-4 font-bold'>Tschechien</h2>
            </div>
            <div>
            +49 3342 354321<br/>
                viva@bbf-bikes.de
            </div>
            </div>
          
            </div>
            <div>
            <div className='hover:bg-gray-700 hover:bg-opacity-50 text-white p-10 w-60 cursor-pointer rounded-lg'>
            <div className='flex pb-5'>
             <img src='https://img.icons8.com/color/96/italy-circular.png'  className='w-8 h-8' alt='italy'/>
             <h2 className='pl-4 font-bold'>Italy</h2>
            </div>
            <div>
            +49 3342 354321<br/>
                viva@bbf-bikes.de
            </div>
            </div>
          
            </div>
            <div>
            <div className='hover:bg-gray-700 hover:bg-opacity-50 text-white p-10 w-60 cursor-pointer rounded-lg'>
            <div className='flex pb-5'>
             <img src='https://img.icons8.com/color/96/france-circular.png'  className='w-8 h-8' alt='france'/>
             <h2 className='pl-4 font-bold'>Frankreich</h2>
            </div>
            <div>
            +49 3342 354321<br/>
                viva@bbf-bikes.de
            </div>
            </div>
          
            </div>
            <div>
            <div className='hover:bg-gray-700 hover:bg-opacity-50 text-white p-10 w-60 cursor-pointer rounded-lg'>
            <div className='flex pb-5'>
             <img src='https://img.icons8.com/color/96/belgium.png'  className='w-8 h-8' alt='belgium'/>
             <h2 className='pl-4 font-bold'>Belgium</h2>
            </div>
            <div>
            +49 3342 354321<br/>
                viva@bbf-bikes.de
            </div>
            </div>
          
            </div>
            <div>
            <div className='hover:bg-gray-700 hover:bg-opacity-50 text-white p-10 w-60 cursor-pointer rounded-lg'>
            <div className='flex pb-5'>
             <img src='https://img.icons8.com/color/96/sweden-circular.png'  className='w-8 h-8' alt='sweden'/>
             <h2 className='pl-4 font-bold'>Sweden</h2>
            </div>
            <div>
            +49 3342 354321<br/>
                viva@bbf-bikes.de
            </div>
            </div>
          
            </div>
            <div>
            <div className='hover:bg-gray-700 hover:bg-opacity-50 text-white p-10 w-60 cursor-pointer rounded-lg'>
            <div className='flex pb-5'>
            <img  src='https://img.icons8.com/fluency/48/usa-circular.png' className='w-8 h-8' alt='United States'/>
             <h2 className='pl-4 font-bold'>United States</h2>
            </div>
            <div>
            +1 844-622-2453<br/>
                viva@bbf-bikes.de
            </div>
            </div>
          
            </div>
            </div>

            </div> 
    </div>
  )
}

export default Distributors