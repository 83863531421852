import React from "react";
import Slider from "../Components/Slider";
import Section from "../Components/Section";
import Footer from "../Components/Footer";
import { useEffect } from "react";
export const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);
  return (
    <div className=" min-h-[calc(100vh-68px)] h-auto bg-[#000000]">
      <Slider />
      <div className="mt-10">
        <Section />
      </div>
    </div>
  );
};
