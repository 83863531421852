import React from "react";
import { Route, Routes } from "react-router-dom";
import { Home } from "./Pages/Home";
import AllBikes from "./Pages/AllBikes";
import CategoryList from "./Components/CategoryList";
import Articles from "./Components/Articles";
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import Dataprotection from "./Pages/Dataprotection";
import Impressum from "./Pages/Impressum";
import Downloads from "./Pages/Downloads";
import AboutUs from "./Pages/AboutUs";
import Dealers from "./Pages/Dealers";
import Contact from "./Pages/Contact";
import { I18nextProvider } from 'react-i18next';
import i18n from './index'
import Distributors from "./Pages/Distributors";
const App = () => {
  return (<I18nextProvider i18n={i18n}>
    <div>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/allbikes" element={<AllBikes />} />
        <Route path="/:categoryname" element={<CategoryList />} />
        <Route path="/:categoryname/:article" element={<Articles />} />
        <Route path="/dataprotection" element={<Dataprotection />} />
        <Route path="/impressum" element={<Impressum />} />
        <Route path="/downloads" element={<Downloads />} />
        <Route path="/aboutus" element={<AboutUs />} />
        <Route path="/dealers" element={<Dealers />} />
        <Route path="/distributors" element={<Distributors/>} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
      <Footer />
    </div>
    </I18nextProvider>
  );
};

export default App;
