import axios from "axios";
import { useState, useEffect, useRef } from "react";
import {
  TileLayer,
  MapContainer,
  Marker,
  Popup,
  ZoomControl,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import MarkerIcon from "../Images/Marker.png";
import MarkerIcon2 from "../Images/Marker2.png";
import L from "leaflet";
import { useTranslation } from "react-i18next";
function Dealers() {
  const [stores, setStores] = useState([]);
  const [searchPlace, setsearchPlace] = useState("");
  const [filteredBySearch, setFilteredBySearch] = useState([]);
  const [selectedStore, setSelectedStore] = useState();
  const [center, setCenter] = useState([51.1657, 10.4515]);
  const [zoom, setZoom] = useState(6);
  const [viva, setViva] = useState([]);
  const { t } = useTranslation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const customIcon = L.icon({
    iconUrl: MarkerIcon,
    iconSize: [32, 32],
    iconAnchor: [16, 32],
    popupAnchor: [0, -32],
  });

  const customIcon2 = L.icon({
    iconUrl: MarkerIcon2,
    iconSize: [32, 32],
    iconAnchor: [16, 32],
    popupAnchor: [0, -32],
  });

  const fetchStores = async () => {
    try {
      const response = await axios.get("https://api.bbf-bike.de/api/reseller");
      setStores(response.data);
    } catch (error) {
      console.log("Error gets generated", error);
    }
  };
  useEffect(() => {
    fetchStores();
  }, []);
  const showAll = () => {
    setViva(findViva);
    setFilteredBySearch([]);
  };

  const findViva = stores?.filter((item) => item?.tag?.includes("viva"));

  useEffect(() => {
    setViva(findViva);
  }, [stores]);

  const handlePlaceSearch = (place) => {
    if (place) {
      const lowerCaseNameOrOrt = place.toLowerCase();
      const filtered = findViva.filter(
        (item) =>
          item.name.toLowerCase().includes(lowerCaseNameOrOrt) ||
          item.ort.toLowerCase().includes(lowerCaseNameOrOrt)
      );
      setFilteredBySearch(filtered);
      setSelectedStore(undefined);
      setViva(null);
    }
  };
  const focusThePlace = (item) => {
    setSelectedStore(item);
    setCenter([item.position[0], item.position[1]]);
    setZoom(10);
  };
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handlePlaceSearch(searchPlace);
    }
  };
  console.log(viva)
  return (
    <div className="min-h-[calc(100vh-68px)] h-auto bg-[#000000]">
      <div className="flex justify-center items-center  sm:flex-col lg:flex-row sm:flex-wrap md:pt-40 sm:pt-20">
        <h1 className="md:text-5xl sm:text-3xl font-extrabold text-white">
          {t("Storelocator")}
        </h1>
      </div>
      <label className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">
        Search
      </label>
      <div className="flex flex-col">
        <div className="md:flex md:flex-row md:justify-center md:items-center sm:flex sm:flex-col sm:justify-center sm:items-center mt-10">
          <div className="relative md:w-[400px] sm:w-[300px]">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <svg
                className="w-4 h-4 text-gray-500 dark:text-gray-400"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 20"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                />
              </svg>
            </div>
            <input
              type="search"
              id="default-search"
              value={searchPlace}
              onChange={(e) => setsearchPlace(e.target.value)}
              onKeyDown={handleKeyDown}
              className="block w-full p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 outline-none"
              placeholder={t("Search")}
              required
            />
            <button
              onClick={() => handlePlaceSearch(searchPlace)}
              className="text-white absolute right-2.5 bottom-2.5 bg-[#A07A10] hover:bg-[#A07A10] focus:outline-none  font-medium rounded-lg text-sm px-4 py-2 "
            >
              {t("Search")}
            </button>
          </div>
          <div className="flex justify-center items-center ml-3 sm:mt-4 md:mt-0">
            <button
              onClick={showAll}
              className="bg-[#A07A10] text-white px-3 py-2 rounded-lg hover:bg-opacity-80 font-regular"
            >
              {t("Show all")}
            </button>
          </div>
        </div>
      </div>
      <div className="flex justify-center items-center mt-10 mb-12">
        <div className="md:h-[600px] md:w-[1200px] sm:h-[400px] sm:w-[350px]">
          <MapContainer
            center={center}
            zoom={zoom}
            style={{ height: "100%", width: "100%" }}
            zoomControl={true}
            className="z-0"
          >
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />

            <ZoomControl position="topright" />
            {viva ? (
              <div>
                {viva?.map((place,index) => (
                  <div     key={index}>
                    {
                    place?.position[0] && place?.position[1] &&
                    <Marker
                
                    icon={customIcon2}
                    position={[place?.position[0], place?.position[1]]}
                  >
                    <Popup>
                      <div>
                        <strong>{place.name}</strong>
                        <br />
                        {`Address:${place.adresse}`}
                        <br />
                        {`Tel.:${place.tel}`}
                        <br />
                        {`E-Mail:${place.email}`}
                      </div>
                    </Popup>
                  </Marker>
                  }
                 
                  </div>
                ))}
              </div>
            ) : (
              <div className="text-[20px] text-center"> "loading ......"</div>
             
            )}

            {selectedStore ? (
              <Marker
                key={selectedStore.id}
                icon={customIcon}
                position={[
                  selectedStore?.position[0],
                  selectedStore?.position[1],
                ]}
              >
                <Popup>
                  <div>
                    <strong>{selectedStore.name}</strong>
                    <br />
                    {`Address:${selectedStore.adresse}`}
                    <br />
                    {`Tel.:${selectedStore.tel}`}
                    <br />
                    {`E-Mail:${selectedStore.email}`}
                  </div>
                </Popup>
              </Marker>
            ) : filteredBySearch ? (
              <div>
                {filteredBySearch?.map((place,index) => (
                  <Marker
                    key={index}
                    icon={customIcon2}
                    position={[place?.position[0], place?.position[1]]}
                  >
                    <Popup>
                      <div>
                        <strong>{place.name}</strong>
                        <br />
                        {`Address:${place.adresse}`}
                        <br />
                        {`Tel.:${place.tel}`}
                        <br />
                        {`E-Mail:${place.email}`}
                      </div>
                    </Popup>
                  </Marker>
                ))}
              </div>
            ) : (
              <div></div>
            )}
          </MapContainer>
        </div>
      </div>
      <div className=" pb-10 sm:mx-10 lg:mx-20">
        {filteredBySearch ? (
          <div className="flex justify-center items-center">
            <div className="grid md:grid-cols-3 sm:grid-cols-1 gap-10 text-white">
              {filteredBySearch.map((item, index) => (
                <div
                  onClick={() => focusThePlace(item)}
                  className=" border-2 border-white p-5 cursor-pointer rounded-lg hover:opacity-80"
                  key={index}
                >
                  <div>{item.name}</div>
                  <div>{item.adresse}</div>
                  <div>{item.tel}</div>
                  <div>
                    {" "}
                    <a
                      href={`https://${item.web}`}
                      target="blank"
                      className="text-blue-500 hover:text-green-700"
                    >
                      {item.web}
                    </a>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default Dealers;
