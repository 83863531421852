import React from "react";
import AllCategories from "../Components/AllCategories";
import { useEffect } from "react";
const AllBikes = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);
  return (
    <div>
      <AllCategories />
    </div>
  );
};

export default AllBikes;
