import React from "react";
import Scroller from "./Scroller";
import { Link } from "react-router-dom";
import {useTranslation} from "react-i18next"
const Footer = () => {
  const {t}= useTranslation();
 const date= new Date()
 const year= date.getFullYear()
  return (
    <div>
      <Scroller />
      <footer className="bg-[#A07A10] ">
        <div className="mx-auto w-full ">
          <div className="px-4 py-6 bg-[#A07A10] md:flex-row md:flex md:items-center md:justify-center sm:flex sm:flex-col ">
            <Link to="/dataprotection">
              <div className="text-sm text-white dark:text-gray-300 sm:text-center sm:mr-4 md:mr-2">
              {t("Dataprotection")}
              </div>
            </Link>
            <Link to="/impressum">
              {" "}
              <div className="text-sm text-white dark:text-gray-300 sm:text-center mr-5">
              {t("Imprint")}
              </div>
            </Link>

            <span className="text-sm text-white dark:text-gray-300 sm:text-center">
              © {year} <Link to="/">VIVA</Link> {t("All Rights Reserved")}.
              
            </span>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
